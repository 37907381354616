import React, { useEffect, useState } from 'react';
import backgroundImage from '../../images/PCB.jpg';

export default function Counter() {
  // State for the counter values
  const [yearsInIndustry, setYearsInIndustry] = useState(0);
  const [projectsCompleted, setProjectsCompleted] = useState(0);

  // Counter effect
  useEffect(() => {
    const yearsTarget = 20; // Target for years in the industry
    const projectsTarget = 10000; // Target for projects completed

    // Increment years in industry
    const yearsInterval = setInterval(() => {
      setYearsInIndustry((prev) => {
        if (prev < yearsTarget) return prev + 1;
        clearInterval(yearsInterval);
        return yearsTarget;
      });
    }, 120); // Change the interval speed as needed

    // Increment projects completed
    const projectsInterval = setInterval(() => {
      setProjectsCompleted((prev) => {
        if (prev < projectsTarget) return prev + 100; // Increment by 100 for quicker display
        clearInterval(projectsInterval);
        return projectsTarget;
      });
    }, 40); // Change the interval speed as needed

    return () => {
      clearInterval(yearsInterval);
      clearInterval(projectsInterval);
    };
  }, []);

  return (
    <div className="relative w-full">
      {/* Background Image Section */}
      <div 
        className="absolute inset-0 bg-cover bg-center opacity-75" 
        style={{ backgroundImage: `url(${backgroundImage})` }}
      />
      {/* Counter Section */}
      <div className="relative flex justify-around items-center h-60 text-white z-10">
        <div className="text-center bg-gray-950 bg-opacity-50 p-10 w-80 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold pb-5">Years in Industry</h2>
          <h1 className="text-6xl font-bold">{yearsInIndustry}+</h1>
        </div>
        <div className="text-center bg-gray-950 bg-opacity-50 p-10 w-80 rounded-lg shadow-md">
          <h3 className="text-2xl font-bold pb-5">Projects Completed</h3>
          <h1 className="text-6xl font-bold">{projectsCompleted}+</h1>
        </div>
      </div>
    </div>
  );
}
