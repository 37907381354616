import React from 'react';
import PCBAssemblyVideo from '../../../videos/PCB Automatic Assembly Manufacturer.webm';

export default function PCBAutomaticAssembly() {
    return (
        <div className="bg-white p-4 sm:p-6 md:p-8 lg:p-10 text-gray-700">
            <div className="flex flex-col md:flex-row items-start gap-6 h-auto">
                <div className="space-y-4 w-full md:w-1/2">
                    <p className="leading-relaxed text-gray-700 text-sm sm:text-base">
                        With hard work and sincerity of our employees, we have carved a place for ourselves in this realm by providing Automatic Assembly. Under the direction of competent personnel, these provided products are delivered with superiority. Apart from this, owing to their flexibility, affordability, and reliability, these provided products are highly in demand.
                        PCB automatic assembly is the use of machines to automate the process of assembling electronic components onto a printed circuit board (PCB). This is in contrast to manual PCB assembly, which is done by humans.
                    </p>
                    <h3 className="text-md sm:text-lg font-semibold text-gray-800">Features:</h3>
                    <ul className="list-disc list-inside pl-4 text-gray-700 text-sm sm:text-base">
                        <li>Durable</li>
                        <li>Longer life</li>
                        <li>Quality assured</li>
                        <li>Reduced labor costs: Automated assembly machines can reduce the need for human labor, helping lower the cost of PCB assembly.</li>
                    </ul>
                </div>
                <div className="w-full md:w-1/2 h-40 sm:h-48 md:h-64 lg:h-72">
                    <iframe
                        src={PCBAssemblyVideo}
                        className="w-full h-full rounded-lg shadow-lg object-cover"
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
            <p className="leading-relaxed mt-6 text-gray-700 text-sm sm:text-base">
                Automated PCB assembly has several advantages over manual assembly, including increased speed, as automated machines place components faster than humans, leading to shorter assembly times and faster time to market for electronic products. Automated assembly also improves accuracy, with machines placing components more precisely than humans, reducing defects in PCB assemblies. Lastly, it ensures increased consistency, as automated machines place components in the same way every time, helping to maintain high-quality PCB assemblies that meet customer specifications.
            </p>
        </div>
    );
}
