import React from 'react';
import chooseImg from '../../images/choose-img.jpg';

export default function WhyChoose() {
  return (
    <div className="max-w-7xl mx-auto p-8">
      {/* Title */}
      <h1 className="text-4xl text-center font-bold text-[#150A76] mb-8">Why Choose Us</h1>

      {/* First row */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
        <div className="bg-blue-200 p-6 rounded-lg shadow-md text-center">
          <h2 className="text-lg font-semibold text-blue-800 mb-2">Extensive Business Network</h2>
        </div>
        <div className="bg-blue-200 p-6 rounded-lg shadow-md text-center">
          <h2 className="text-lg font-semibold text-blue-800 mb-2">International Quality Standards</h2>
        </div>
        <div className="bg-blue-200 p-6 rounded-lg shadow-md text-center">
          <h2 className="text-lg font-semibold text-blue-800 mb-2">Expert Guidance</h2>
        </div>
      </div>

      {/* Second row */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
        {/* Left Div */}
        <div className="bg-blue-200 p-6 rounded-lg shadow-md flex items-center justify-center">
          <h2 className="text-lg font-semibold text-blue-800">Skilled Team</h2>
        </div>

        {/* Center Image Div */}
        <div className="bg-gray-100 p-2 rounded-lg shadow-md flex items-center justify-center">
          <img src={chooseImg} alt="Team Image" className="w-full h-auto object-cover rounded-lg" />
        </div>

        {/* Right Div */}
        <div className="bg-blue-200 p-6 rounded-lg shadow-md flex items-center justify-center">
          <h2 className="text-lg font-semibold text-blue-800">Innovative Solutions</h2>
        </div>
      </div>

      {/* Third row */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
        <div className="bg-blue-200 p-6 rounded-lg shadow-md text-center">
          <h2 className="text-lg font-semibold text-blue-800 mb-2">Consistent Product Offerings</h2>
        </div>
        <div className="bg-blue-200 p-6 rounded-lg shadow-md text-center">
          <h2 className="text-lg font-semibold text-blue-800 mb-2">Diverse Client Base</h2>
        </div>
        <div className="bg-blue-200 p-6 rounded-lg shadow-md text-center">
          <h2 className="text-lg font-semibold text-blue-800 mb-2">Trusted Market Presence</h2>
        </div>
      </div>

      {/* Content Paragraphs */}
      <div className="bg-white p-6 rounded-lg shadow-md text-left space-y-4">
        {/* First paragraph */}
        <p>
          Our business network is extensive and we supply our unmatched collections all across the nation. 
          We are based in Maharashtra and maintain international standard of quality in all our products. 
          These are all precisely devised under the guidance of experts and are duly verified on various aspects. 
          Our teams include well experienced technocrats, engineers, quality experts and workforces that are all well 
          trained to apply their innovative and advanced skills to meet the growing demands of the market.
        </p>

        {/* Second paragraph */}
        <p>
          With our constant efforts, we are able to cater consistent products in various designs and sizes. 
          These are high in demand and are safely supplied to various commercial, industrial, and residential clients. 
          Our optimum approaches help us to emerge as the most trusted name of the market.
        </p>
      </div>
    </div>
  );
}
