import React, { useState } from 'react';

export default function ProtoForm() {
    const [formData, setFormData] = useState({
        name: '',
        number: '',
        email: '',
        subject: '',
        assemblyType: '',
    });

    const [errors, setErrors] = useState({
        number: '',
    });

    const handleChange = (e) => {
        const { name, value, type } = e.target;

        // Number validation for exactly 10 digits
        if (name === 'number') {
            const numberRegex = /^[0-9]*$/; // Only digits allowed
            if (numberRegex.test(value) && value.length <= 10) {
                setFormData({ ...formData, [name]: value });
                setErrors({ ...errors, number: '' }); // Clear error if valid
            } else {
                setErrors({ ...errors, number: 'Please enter a valid 10-digit number.' });
            }
            return;
        }

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Check if the phone number is exactly 10 digits before submitting
        if (formData.number.length !== 10) {
            setErrors({ ...errors, number: 'Number must be exactly 10 digits.' });
            return;
        }

        console.log(formData);
        // Add your form submission logic here (e.g., API call)
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100 p-8">
            <form onSubmit={handleSubmit} className="w-full max-w-lg bg-white p-8 rounded-lg shadow-md">
                {/* Name Input */}
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                        Name
                    </label>
                    <input
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Enter your name"
                        value={formData.name}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                    />
                </div>

                {/* Number Input */}
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="number">
                        Number
                    </label>
                    <input
                        id="number"
                        name="number"
                        type="tel"
                        placeholder="Enter your number"
                        value={formData.number}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                    />
                    {errors.number && <p className="text-red-500 text-xs mt-2">{errors.number}</p>}
                </div>

                {/* Email Input */}
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                        Email
                    </label>
                    <input
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Enter your email"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                    />
                </div>

                {/* Subject Input */}
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="subject">
                        Subject
                    </label>
                    <input
                        id="subject"
                        name="subject"
                        type="text"
                        placeholder="Enter subject"
                        value={formData.subject}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                    />
                </div>

                {/* Radio Input for Assembly Type (PCBA) */}
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Assembly Type (PCBA)</label>
                    <div className="flex items-center mb-2">
                        <input
                            id="pcba"
                            name="assemblyType"
                            type="radio"
                            value="PCBA"
                            onChange={handleChange}
                            className="mr-2"
                        />
                        <label htmlFor="pcba" className="text-gray-700">PCBA</label>
                    </div>
                    <div className="flex items-center mb-2">
                        <input
                            id="smd-pcba"
                            name="assemblyType"
                            type="radio"
                            value="SMD"
                            onChange={handleChange}
                            className="mr-2"
                        />
                        <label htmlFor="smd-pcba" className="text-gray-700">SMD</label>
                    </div>
                    <div className="flex items-center mb-2">
                        <input
                            id="through-hole-pcba"
                            name="assemblyType"
                            type="radio"
                            value="THROUGH HOLE"
                            onChange={handleChange}
                            className="mr-2"
                        />
                        <label htmlFor="through-hole-pcba" className="text-gray-700">Through Hole</label>
                    </div>
                    <div className="flex items-center mb-2">
                        <input
                            id="both-pcba"
                            name="assemblyType"
                            type="radio"
                            value="Both (SMD + Through Hole)"
                            onChange={handleChange}
                            className="mr-2"
                        />
                        <label htmlFor="both-pcba" className="text-gray-700">Both (SMD + Through Hole)</label>
                    </div>
                </div>

                {/* Radio Input for Assembly Type (PCBM) */}
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Assembly Type (PCBM)</label>
                    <div className="flex items-center mb-2">
                        <input
                            id="pcbm"
                            name="assemblyType"
                            type="radio"
                            value="PCBM"
                            onChange={handleChange}
                            className="mr-2"
                        />
                        <label htmlFor="pcbm" className="text-gray-700">PCBM</label>
                    </div>
                    <div className="flex items-center mb-2">
                        <input
                            id="single-layer"
                            name="assemblyType"
                            type="radio"
                            value="SINGLE LAYER"
                            onChange={handleChange}
                            className="mr-2"
                        />
                        <label htmlFor="single-layer" className="text-gray-700">Single Layer</label>
                    </div>
                    <div className="flex items-center mb-2">
                        <input
                            id="double-layer"
                            name="assemblyType"
                            type="radio"
                            value="DOUBLE LAYER"
                            onChange={handleChange}
                            className="mr-2"
                        />
                        <label htmlFor="double-layer" className="text-gray-700">Double Layer</label>
                    </div>
                    <div className="flex items-center mb-2">
                        <input
                            id="multi-layer"
                            name="assemblyType"
                            type="radio"
                            value="MULTI LAYER"
                            onChange={handleChange}
                            className="mr-2"
                        />
                        <label htmlFor="multi-layer" className="text-gray-700">Multi Layer</label>
                    </div>
                </div>

                {/* Submit Button */}
                <div className="text-center">
                    <button
                        type="submit"
                        className="bg-blue-500 text-white px-6 py-2 rounded-lg shadow hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
}
