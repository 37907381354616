import React, { useState } from 'react';

const FramelessStencilForm = () => {
    const [formData, setFormData] = useState({
        stencilName: '',
        stencilPrimer: '',
        stencilType: '',
        sizeX: '',
        sizeY: '',
        leadTime: '',
        quantity: '',
        top: '',
        bottom: '',
        both: '',
        thickness: '',
        specialRequirement: '',
        firstName: '',
        lastName: '',
        gerberFile: null
    });

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'file' ? files[0] : value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic
        console.log(formData);
    };

    return (
        <div className="min-h-screen bg-gray-100 flex items-center justify-center">
            <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-2xl">
                <h1 className="text-2xl font-bold mb-6">Frameless Stencil Form</h1>
                <form onSubmit={handleSubmit} className="space-y-6">
                    {/* Stencil Name */}
                    <div>
                        <label className="block text-sm font-medium mb-2">Stencil Name</label>
                        <input
                            type="text"
                            name="stencilName"
                            value={formData.stencilName}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        />
                    </div>

                    {/* Stencil Primer */}
                    <div>
                        <label className="block text-sm font-medium mb-2">Stencil Primer</label>
                        <input
                            type="text"
                            name="stencilPrimer"
                            value={formData.stencilPrimer}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        />
                    </div>

                    {/* Stencil Type */}
                    <div>
                        <label className="block text-sm font-medium mb-2">Stencil Type</label>
                        <input
                            type="text"
                            name="stencilType"
                            value={formData.stencilType}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        />
                    </div>

                    {/* Size X */}
                    <div>
                        <label className="block text-sm font-medium mb-2">Size X</label>
                        <select
                            name="sizeX"
                            value={formData.sizeX}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        >
                            <option value="">Select Size X</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="300">300</option>
                        </select>
                    </div>

                    {/* Size Y */}
                    <div>
                        <label className="block text-sm font-medium mb-2">Size Y</label>
                        <select
                            name="sizeY"
                            value={formData.sizeY}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        >
                            <option value="">Select Size Y</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="300">300</option>
                        </select>
                    </div>

                    {/* Lead Time */}
                    <div>
                        <label className="block text-sm font-medium mb-2">Lead Time</label>
                        <input
                            type="text"
                            name="leadTime"
                            value={formData.leadTime}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        />
                    </div>

                    {/* Quantity */}
                    <div>
                        <label className="block text-sm font-medium mb-2">Quantity</label>
                        <input
                            type="number"
                            name="quantity"
                            value={formData.quantity}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        />
                    </div>

                    {/* Top */}
                    <div>
                        <label className="block text-sm font-medium mb-2">Top</label>
                        <select
                            name="top"
                            value={formData.top}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        >
                            <option value="">Select Top</option>
                            <option value="Option1">Option 1</option>
                            <option value="Option2">Option 2</option>
                        </select>
                    </div>

                    {/* Bottom */}
                    <div>
                        <label className="block text-sm font-medium mb-2">Bottom</label>
                        <select
                            name="bottom"
                            value={formData.bottom}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        >
                            <option value="">Select Bottom</option>
                            <option value="Option1">Option 1</option>
                            <option value="Option2">Option 2</option>
                        </select>
                    </div>

                    {/* Both */}
                    <div>
                        <label className="block text-sm font-medium mb-2">Both</label>
                        <select
                            name="both"
                            value={formData.both}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        >
                            <option value="">Select Both</option>
                            <option value="Option1">Option 1</option>
                            <option value="Option2">Option 2</option>
                        </select>
                    </div>

                    {/* Thickness (mil) */}
                    <div>
                        <label className="block text-sm font-medium mb-2">Thickness (mil)</label>
                        <select
                            name="thickness"
                            value={formData.thickness}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        >
                            <option value="">Select Thickness</option>
                            <option value="1mil">1 mil</option>
                            <option value="2mil">2 mil</option>
                            <option value="3mil">3 mil</option>
                        </select>
                    </div>

                    {/* Special Requirement */}
                    <div>
                        <label className="block text-sm font-medium mb-2">Special Requirement</label>
                        <textarea
                            name="specialRequirement"
                            value={formData.specialRequirement}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded"
                            rows="4"
                        />
                    </div>

                    {/* First Name */}
                    <div>
                        <label className="block text-sm font-medium mb-2">First Name</label>
                        <input
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        />
                    </div>

                    {/* Last Name */}
                    <div>
                        <label className="block text-sm font-medium mb-2">Last Name</label>
                        <input
                            type="text"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        />
                    </div>

                    {/* Gerber File Upload */}
                    <div>
                        <label className="block text-sm font-medium mb-2">Gerber File</label>
                        <input
                            type="file"
                            name="gerberFile"
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        />
                    </div>

                    <div>
                        <button
                            type="submit"
                            className="w-auto bg-blue-600 text-white p-2 rounded-lg hover:bg-blue-700"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default FramelessStencilForm;
