import React from 'react'
import LEDlightHead from '../components/Services/LED Light Circuit Board Assembly/LEDLightCircuitHead'
import LEDlight from '../components/Services/LED Light Circuit Board Assembly/LEDLightCircuit'
export default function LEDlightPage() {
  return (
    <div>
      <LEDlightHead/>
      <LEDlight/>
    </div>
  )
}
