import React from 'react'
import SMDHead from '../components/Services/SMD/SMDHead'
import SMD from '../components/Services/SMD/SMD.jsx'

export default function SMDpage() {
  return (
    <div>
        <SMDHead/>
        <SMD/>
    </div>
  )
}
