import React from 'react';
import Service1 from '../../images/icons/service1.png';
import Service2 from '../../images/icons/service2.png';
import Service3 from '../../images/icons/service3.png';
import Service4 from '../../images/icons/service4.png';
import Service5 from '../../images/icons/service5.png';
import Service6 from '../../images/icons/service6.png';
import Service7 from '../../images/icons/service7.png';
import Service8 from '../../images/icons/service8.png';
import backgroundPCB from '../../images/PCB.jpg';


export default function ServiceForConsulting() {
  return (
    <div className=" max-w-full mx-auto p-8"
      style={{ 
        backgroundImage: `url(${backgroundPCB})`, // Corrected syntax for background image
        backgroundSize: 'contain' 
      }}>
      <div className="bg-gray-950 bg-opacity-5 ">
      <h1 className='text-4xl text-center font-bold mb-7 text-white'>
      We Provide The Best Service For Consulting</h1>
      </div>  
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {/* Service 1 */}
        <div className="bg-white bg-opacity-80 border border-gray-300 p-8 rounded-lg shadow-md text-center">
          <img src={Service1} alt="SMD Solutions" className="mx-auto w-24 h-24 mb-4" />
          <h4 className="text-lg font-semibold mb-2">Provide SMD Solutions</h4>
          <p className="text-gray-600">Surface-mount innovation (SMT), initially called planar mounting, is a strategy where the electrical parts are mounted straightforwardly onto the outer layer of a printed circuit board (PCB). An electrical part mounted as such is alluded to as a surface-mount gadget (SMD).</p>
        </div>

        {/* Service 2 */}
        <div className="bg-white bg-opacity-80 border border-gray-300 p-8 rounded-lg shadow-md text-center">
          <img src={Service2} alt="EMS Solutions" className="mx-auto w-24 h-24 mb-4" />
          <h4 className="text-lg font-semibold mb-2">Provide EMS Solutions</h4>
          <p className="text-gray-600">Electronic Designing Assembling Administration alludes to the system of coordinating item improvement, prototyping and industrialization administrations into a conventional EMS business, with the plan to bridle expected cooperative energies.</p>
        </div>

        {/* Service 3 */}
        <div className="bg-white bg-opacity-80 border border-gray-300 p-8 rounded-lg shadow-md text-center">
          <img src={Service3} alt="Packing Facilities" className="mx-auto w-24 h-24 mb-4" />
          <h4 className="text-lg font-semibold mb-2">Avails Adequate Packing Facilities Of Material</h4>
          <p className="text-gray-600">An ordinary E2MS offering will begin in the plan stage, then, at that point, keep on supporting the client being developed, prototyping, tooling, and creation the whole way to the testing stage, considering quicker increase as the item is ready for large scale manufacturing front.</p>
        </div>

        {/* Service 4 */}
        <div className="bg-white bg-opacity-80 border border-gray-300 p-8 rounded-lg shadow-md text-center">
          <img src={Service4} alt="Assembly Process" className="mx-auto w-24 h-24 mb-4" />
          <h4 className="text-lg font-semibold mb-2">Facilitates Circuits Assembly Process And Contractual Manufacturing</h4>
          <p className="text-gray-600">A mechanical production system is an assembling cycle (frequently called an ever-evolving gathering) in what parts (typically exchangeable parts) are added as the semi-completed gathering moves from one workstation to another.</p>
        </div>

        {/* Service 5 */}
        <div className="bg-white bg-opacity-80 border border-gray-300 p-8 rounded-lg shadow-md text-center">
          <img src={Service5} alt="Contractual Manufacturing" className="mx-auto w-24 h-24 mb-4" />
          <h4 className="text-lg font-semibold mb-2">Undertake Contractual Manufacturing</h4>
          <p className="text-gray-600">Where the parts are included succession until the last gathering is delivered. By precisely moving the parts to the gathering work and moving the semi-completed get together from work station to work station, a completed item can be collected quicker.</p>
        </div>

        {/* Service 6 */}
        <div className="bg-white bg-opacity-80 border border-gray-300 p-8 rounded-lg shadow-md text-center">
          <img src={Service6} alt="PB Assembly" className="mx-auto w-24 h-24 mb-4" />
          <h4 className="text-lg font-semibold mb-2">Undertakes Complete PB Assembly Services As Per The Requirements</h4>
          <p className="text-gray-600">EMS players have moved creation to minimal expense geologies; embraced modern businesses including purchaser hardware, modern, clinical, and instrumentation; and added significant vertical capacities, extending from plan.</p>
        </div>

        {/* Service 7 */}
        <div className="bg-white bg-opacity-80 border border-gray-300 p-8 rounded-lg shadow-md text-center">
          <img src={Service7} alt="Leaded Through Holes" className="mx-auto w-24 h-24 mb-4" />
          <h4 className="text-lg font-semibold mb-2">Undertakes Leaded Through holes Assemblies</h4>
          <p className="text-gray-600">Through-opening innovation is an assembling plan in which leads on the parts are embedded through openings bored in printed circuit sheets (PCB) and welded to cushions on the contrary side, either by manual gathering or robotized insertion.</p>
        </div>

        {/* Service 8 */}
        <div className="bg-white bg-opacity-80 border border-gray-300 p-8 rounded-lg shadow-md text-center">
          <img src={Service8} alt="Quality Check" className="mx-auto w-24 h-24 mb-4" />
          <h4 className="text-lg font-semibold mb-2">Provides Required Quality Check Of Finished Assembly</h4>
          <p className="text-gray-600">ODM through framework gathering, test, conveyance and strategies, guarantee and fix, network administrations, programming and silicon plan, and client care are utilized to electrically and thermally join follows, cushions, and polygons on various layers of a PCB.</p>
        </div>
      </div>
    </div>
  );
}
