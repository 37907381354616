import React, { useState } from 'react';

const FramedStencilForm = () => {
    const [formData, setFormData] = useState({
        stencilName: '',
        stencilPrinter: '',
        stencilType: '',
        size: '',
        leadTime: '',
        quantity: '',
        topIP: '',
        bottomIP: '',
        bothIPThickness: '',
        specialRequirement: '',
        firstName: '',
        lastName: '',
        gerberFile: null
    });

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'file' ? files[0] : value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic
        console.log(formData);
    };

    return (
        <div className="min-h-screen bg-gray-100 flex items-center justify-center">
            <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-2xl">
                <h1 className="text-2xl font-bold mb-6">Framed Stencil Form</h1>
                <form onSubmit={handleSubmit} className="space-y-6">
                    {/* Stencil Name */}
                    <div>
                        <label className="block text-sm font-medium mb-2">Stencil Name</label>
                        <input
                            type="text"
                            name="stencilName"
                            value={formData.stencilName}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        />
                    </div>

                    {/* Stencil Printer */}
                    <div>
                        <label className="block text-sm font-medium mb-2">Stencil Printer</label>
                        <input
                            type="text"
                            name="stencilPrinter"
                            value={formData.stencilPrinter}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        />
                    </div>

                    {/* Stencil Type */}
                    <div>
                        <label className="block text-sm font-medium mb-2">Stencil Type</label>
                        <input
                            type="text"
                            name="stencilType"
                            value={formData.stencilType}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        />
                    </div>

                    {/* Size (X or Y) */}
                    <div>
                        <label className="block text-sm font-medium mb-2">Size</label>
                        <select
                            name="size"
                            value={formData.size}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        >
                            <option value="">Select Size</option>
                            <option value="X">X</option>
                            <option value="Y">Y</option>
                        </select>
                    </div>

                    {/* Lead Time */}
                    <div>
                        <label className="block text-sm font-medium mb-2">Lead Time</label>
                        <input
                            type="text"
                            name="leadTime"
                            value={formData.leadTime}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        />
                    </div>

                    {/* Quantity */}
                    <div>
                        <label className="block text-sm font-medium mb-2">Quantity</label>
                        <input
                            type="number"
                            name="quantity"
                            value={formData.quantity}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        />
                    </div>

                    {/* Top IP */}
                    <div>
                        <label className="block text-sm font-medium mb-2">Top IP</label>
                        <select
                            name="topIP"
                            value={formData.topIP}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        >
                            <option value="">Select Top IP</option>
                            <option value="Option1">Option 1</option>
                            <option value="Option2">Option 2</option>
                        </select>
                    </div>

                    {/* Bottom IP */}
                    <div>
                        <label className="block text-sm font-medium mb-2">Bottom IP</label>
                        <select
                            name="bottomIP"
                            value={formData.bottomIP}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        >
                            <option value="">Select Bottom IP</option>
                            <option value="Option1">Option 1</option>
                            <option value="Option2">Option 2</option>
                        </select>
                    </div>

                    {/* Both IP Thickness */}
                    <div>
                        <label className="block text-sm font-medium mb-2">Both IP Thickness (mil)</label>
                        <select
                            name="bothIPThickness"
                            value={formData.bothIPThickness}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded"
                        >
                            <option value="">Select Thickness</option>
                            <option value="1mil">1 mil</option>
                            <option value="2mil">2 mil</option>
                            <option value="3mil">3 mil</option>
                        </select>
                    </div>

                    {/* Special Requirement */}
                    <div>
                        <label className="block text-sm font-medium mb-2">Special Requirement</label>
                        <textarea
                            name="specialRequirement"
                            value={formData.specialRequirement}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded"
                            rows="4"
                        />
                    </div>

                    {/* First Name */}
                    <div>
                        <label className="block text-sm font-medium mb-2">First Name</label>
                        <input
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        />
                    </div>

                    {/* Last Name */}
                    <div>
                        <label className="block text-sm font-medium mb-2">Last Name</label>
                        <input
                            type="text"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        />
                    </div>

                    {/* Gerber File Upload */}
                    <div>
                        <label className="block text-sm font-medium mb-2">Gerber File</label>
                        <input
                            type="file"
                            name="gerberFile"
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        />
                    </div>

                    <div>
                        <button
                            type="submit"
                            className="w-auto bg-blue-600 text-white p-2 rounded-lg hover:bg-blue-700"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default FramedStencilForm;
