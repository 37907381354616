import React from 'react'
import ProtoPCBhead from '../components/Solution/PrototypePCB/ProtoPCB_Head'
import Proto from '../components/Solution/PrototypePCB/Proto'
import Protoform from '../components/Solution/PrototypePCB/ProtoForm'

export default function PrototypePCBpage() {
  return (
    <div>
      <ProtoPCBhead/>
      <Proto/>
      <Protoform/>
    </div>
  )
}
