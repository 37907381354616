import React from 'react'
import EMSimg from '../../../images/Solution/WMS.png';

export default function Proto() {
    return (
        <div className="bg-gray-100 text-gray-800 min-h-screen flex flex-col">
            <div className="container bg-white mx-auto px-4 py-10 flex flex-col md:flex-row items-center ">
            
                {/* Text Section */}
                <div className="max-w-[55%] mb-10 md:mb-0 md:mr-10">
                    <p className="mb-6">
                        Prototype PCB (Printed Circuit Board) services are essential for testing design concepts and validating functionality before mass production. These early-stage prototypes allow designers and engineers to identify issues, optimize layouts, and refine their ideas for different electronic devices.

                        PCB manufacturing is the process of creating the boards that are integral to various electronic devices, including smartphones, medical equipment, and computers. The service encompasses several key stages:              
                    </p>

                    <ul className="list-disc list-inside mb-6">
                        <li><strong>Design:</strong> Engineers create a detailed schematic and layout to define the electrical pathways and board structure.</li>
                        <li><strong>Fabrication:</strong> The PCB is physically produced by etching copper traces and building the core board structure.</li>
                        <li><strong>Assembly:</strong>  Components such as resistors, capacitors, and integrated circuits are placed and soldered onto the PCB, using techniques like SMD (Surface-Mount Device) and through-hole technology.</li>
                        <li><strong>Testing:</strong>  The assembled PCB undergoes rigorous testing to ensure it meets design specifications and functions properly.</li>
                    </ul>

                    <h2 className="text-3xl font-semibold mb-4">Features:</h2>
                    <ul className="list-disc list-inside mb-6">
                        <li><strong>Precision Engineering:</strong> Boards are crafted to exact specifications, ensuring accuracy and reliability.</li>
                        <li><strong>High-Quality Materials:</strong> Superior quality materials are used for enhanced durability and performance.</li>
                        <li><strong>Flexible Options:</strong>Both single-layer, double-layer, and multi-layer configurations are available for varying project needs.</li>
                    </ul>
                </div>

                {/* EMS Image Section */}
                <div className="flex justify-center max-w-[45%] mb-10 md:mb-0">
                    <img src={EMSimg} alt="EMS Service" className="max-w-full h-auto rounded shadow-md" />
                </div>
            </div>
        </div>
    );
}
