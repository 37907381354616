import React from 'react';
import mission from '../../images/team-img/mission.png';
import vision from '../../images/team-img/vision.png';

export default function QMS() {
  return (
    <div className="min-h-screen bg-gray-100 py-10">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
          Certified to ISO 9001:2015, we maintain the highest standards in Quality Management Systems.
        </h2>
        <h3 className="text-2xl font-semibold text-center text-gray-700 mb-4">What We Stand For</h3>
        <div className="">

          {/* Mission Section */}
          <div className="relative flex h-72 justify-center"> {/* Set height here */}
            <div
              className="w-[80%] bg-cover bg-center rounded-l-lg"
              style={{ backgroundImage: `url(${mission})` }} // Corrected the syntax here
            >
              <div className="flex justify-end items-center h-full">
                <div className="w-[45%] bg-transparent rounded-r-lg shadow-lg p-6 mr-8">
                  <p className="text-white text-xl font-bold text-left">
                    To evolve into an organization with a reliable and accountable team that fosters a community where individuals of all ages can live with dignity and sustainability.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Vision Section */}
          <div className="relative flex h-72 mt-10 justify-center">
            <div
              className="w-[80%] bg-cover bg-center rounded-r-lg" // Change rounded-l-lg to rounded-r-lg
              style={{ backgroundImage: `url(${vision})` }} // Corrected the syntax here
            >
              <div className="flex justify-start items-center h-full">
                <div className="w-[45%] bg-transparent rounded-l-lg shadow-lg p-6 ml-8">
                  <p className="text-white text-xl font-bold text-left">
                    Our goal is to establish ourselves as a trusted manufacturer of electronic products, consistently meeting customer expectations for quality and service. We strive to treat every inquiry and client with the utmost care and attention, making each one feel valued and unique.
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
