// pages/Home.js
import React from 'react';
import HomeVideo from '../components/frontPage/HomeVideo';
import CompanyProfile from '../components/frontPage/CompanyProfile';
import Counter from '../components/frontPage/Counter';
import MarketServed from '../components/frontPage/MarketServed';
import Expertise from '../components/frontPage/Exprtise';
import RelationsAdvice from '../components/frontPage/RelationsAdvice';

export default function Home() {
  return (
    <>
      <section id="home-video">
        <HomeVideo />
      </section>

      <section id="company-profile">
        <CompanyProfile />
      </section>

      <section id="counter">
        <Counter />
      </section>

      <section id="expertise">
        <Expertise />
      </section>

      <section id="market-served">
        <MarketServed />
      </section>

      <section id="relations-advice">
        <RelationsAdvice />
      </section>
    </>
  );
}
