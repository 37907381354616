import React from 'react';
import techTeam from '../../images/team-img/team6.jpg';
import managTeam from '../../images/team-img/team2.jpeg';
import equipment from '../../images/team-img/testing.jpg';

export default function Teams() {
  return (
    <div className="flex flex-col items-center justify-center p-8">
      {/* Technical Team */}
      <div className="mb-12 w-full max-w-4xl shadow-lg p-6 rounded-lg bg-white">
        <h2 className="text-2xl font-bold mb-4 text-center">Technology Division</h2>
        <div className="flex items-center justify-center space-x-8">
          <div className="w-[60%]">
            <img src={techTeam} alt="Tech Team" className="w-full h-64 object-cover rounded-lg shadow-lg" />
          </div>
          <ul className="list-disc list-inside space-y-2 w-[40%] text-left pl-10">
            <li>6 ENGINEERS</li>
            <li>3 LINE SUPERVISORS</li>
            <li>10 DIPLOMA HOLDERS</li>
            <li>10 ITI HOLDERS</li>
            <li>7 OTHER SEMI-SKILLED STAFF</li>
          </ul>
        </div>
      </div>
      <hr className="h-1 bg-gray-300 animate-slide mb-5" /> {/* Sliding HR */}

      {/* Management Team */}
      <div className="mb-12 w-full max-w-4xl shadow-lg p-6 rounded-lg bg-white">
        <h2 className="text-2xl font-bold mb-4 text-center">Management Division</h2>
        <div className="flex items-center justify-center space-x-8">
          <ul className="list-disc list-inside space-y-2 w-[40%] text-left">
            <li>MANAGING DIRECTOR</li>
            <li>OPERATIONS IN-CHARGE</li>
            <li>HR & ADMIN</li>
            <li>PURCHASER</li>
            <li>ACCOUNTANT</li>
            <li>7 OTHER SEMI-SKILLED STAFF</li>
          </ul>
          <div className="w-[60%]">
            <img src={managTeam} alt="Management Team" className="w-full h-64 object-cover rounded-lg shadow-lg" />
          </div>
        </div>
      </div>
      <hr className="h-1 bg-gray-300 animate-slide mb-5" /> {/* Sliding HR */}

      {/* Testing Equipment We Have */}
      <div className="w-full max-w-4xl shadow-lg p-6 rounded-lg bg-white">
        <h2 className="text-2xl font-bold mb-4 text-center">Our Inventory of Testing Equipment</h2>
        <div className="flex items-center justify-center space-x-8">
          <div className="w-[60%]">
            <img src={equipment} alt="Testing Equipment" className="w-full h-64 object-cover rounded-lg shadow-lg" />
          </div>
          <ul className="list-disc list-inside space-y-2 w-[40%] text-left pl-10">
            <li>2 channel Oscilloscope 200mhz</li>
            <li>DC Electronics load- 200W and 150W</li>
            <li>AC Source 500VA. 0-310 auto mode</li>
            <li>10 ITI HOLDERS</li>
            <li>Power analyzer AC And DC reading</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
