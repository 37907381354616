import React from 'react';
import background from '../../../images/pcb-bg.png';

export default function LEDLightCircuitHead() {
  return (
    <div className="relative w-full h-48 sm:h-60 md:h-80 lg:h-96 overflow-hidden">
      <img 
        src={background} 
        alt="PCB Background" 
        className="w-full h-full object-cover" 
      />
      <h1 className="absolute inset-0 flex items-center justify-center text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-white bg-black bg-opacity-50 text-center px-4">
        LED Light Circuit Board Assembly
      </h1>
    </div>
  );
}
