import React from 'react';
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router-dom for navigation
import StencilForm from './FramedStencilForm';
import EMSimg from '../../../images/Solution/WMS.png';

const StencilProcedurePage = () => {
//     const navigate = useNavigate(); // Hook to handle navigation

    return (
        <div className='min-h-screen flex items-center justify-center bg-gray-100 p-8'>
            <div className="flex w-full max-w-full bg-white rounded-lg shadow-md p-6">
                <div className="w-3/5 pr-4">
                    <h1 className="text-2xl font-bold text-gray-800 mb-4">Stencil Procedure in Visual Art</h1>
                    <p className="text-gray-700 mb-4">
                        The stencil technique, often referred to as <em>pochoir</em>, involves creating images or designs by applying paint or ink over a pre-cut template. This method allows for precision and consistency in reproducing patterns across various surfaces. Another related technique is <em>aerography</em>, commonly utilized in surrealist art, where spray painting is done around a three-dimensional object to produce a negative image, contrasting with the positive outcome of traditional stenciling.
                    </p>

                    <h2 className="text-xl font-semibold text-gray-800 mb-2">Key Features:</h2>
                    <ul className="list-disc list-inside mb-4 text-gray-700">
                        <li><strong>Precise Dimensions:</strong> Ensures accuracy in design size, making it ideal for technical applications like PCB (Printed Circuit Board) stencils.</li>
                        <li><strong>High Quality:</strong> The materials and processes used guarantee a durable and long-lasting stencil.</li>
                        <li><strong>Vibrant Colors:</strong> Advanced color application techniques enhance the visual impact of the artwork.</li>
                    </ul>

                    <h2 className="text-xl font-semibold text-gray-800 mb-2">Types of Stencils:</h2>
                    <ul className="list-disc list-inside mb-4 text-gray-700">
                        <li><strong>PCB Stencil Quotes:</strong> Tailored for PCB manufacturing, ensuring high fidelity in electronic circuit production.</li>
                        <li><strong>Framed Stencils:</strong> These stencils come with a protective frame, making them easy to handle and use.</li>
                        <li><strong>Frameless Stencils:</strong> Lightweight and flexible, perfect for various artistic applications.</li>
                        <li><strong>Step Stencils:</strong> Designed for multi-layered applications, allowing artists to create depth and texture in their work.</li>
                    </ul>

                    <p className="text-gray-700">
                        For more detailed information about stencils and their applications, you can refer to resources on modern artistic techniques and their historical contexts.
                    </p>

                    {/* Buttons Section */}
                    <div className="flex justify-between mt-8">
                        <button 
                            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                            // onClick={() => navigate('/pcb-stencils')}
                        >
                            PCB Stencils
                        </button>
                        <button 
                            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                            // onClick={() => navigate('/framed-stencils')}
                        >
                            Framed Stencils
                        </button>
                        <button 
                            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                            // onClick={() => navigate('/step-stencils')}
                        >
                            Step Stencils
                        </button>
                    </div>
                </div>

                {/* Image Section */}
                <div className="w-2/5 flex justify-center">
                    <img src={EMSimg} alt="EMS Service" className="max-w-full h-auto rounded shadow-md" />
                </div>
            </div>
        </div>
    );
};

export default StencilProcedurePage;
