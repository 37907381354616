import React from 'react';
import icon1 from '../../images/icons/icon3new.png';
import icon2 from '../../images/icons/quality_Icon.png';
import icon3 from '../../images/icons/Facilator_icon.png';
import icon4 from '../../images/icons/delivery_icon.png';
import icon5 from '../../images/icons/Excellance_icon.png';
import icon6 from '../../images/icons/Protection_icon.png';
import icon7 from '../../images/icons/leadership_icon.png';
import icon8 from '../../images/icons/partner_icon.png';

export default function Facility() {
  return (
    <div className="max-w-7xl mx-auto p-8">
      <h4 className="text-l font-bold text-center text-[#150A76] mb-8">Our mentor, Abhishek R. Ingole, has been supervising us to apply superior ability to cater advanced range of products. His talent, experience and support help us to reach the best height of success”
      Our specialty lies in “Quality Assembly Processing” and “Speedy Delivery.</h4>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {/* Icon 1 */}
        <div className="text-center border border-gray-300 rounded-lg p-4">
          <img src={icon1} alt="World class EMS provider" className="mx-auto h-20 w-20 mb-4"/>
          <h3 className="text-lg font-semibold">World class EMS provider</h3>
        </div>

        {/* Icon 2 */}
        <div className="text-center border border-gray-300 rounded-lg p-4">
          <img src={icon2} alt="Quality is our attitude" className="mx-auto h-20 w-20 mb-4"/>
          <h3 className="text-lg font-semibold">Quality is our attitude</h3>
        </div>

        {/* Icon 3 */}
        <div className="text-center border border-gray-300 rounded-lg p-4">
          <img src={icon3} alt="True Facilitator" className="mx-auto h-20 w-20 mb-4"/>
          <h3 className="text-lg font-semibold">True Facilitator</h3>
        </div>

        {/* Icon 4 */}
        <div className="text-center border border-gray-300 rounded-lg p-4">
          <img src={icon4} alt="On-time delivery" className="mx-auto h-20 w-20 mb-4"/>
          <h3 className="text-lg font-semibold">On-time delivery</h3>
        </div>

        {/* Icon 5 */}
        <div className="text-center border border-gray-300 rounded-lg p-4">
          <img src={icon5} alt="Excellence" className="mx-auto h-20 w-20 mb-4"/>
          <h3 className="text-lg font-semibold">Excellence</h3>
        </div>

        {/* Icon 6 */}
        <div className="text-center border border-gray-300 rounded-lg p-4">
          <img src={icon6} alt="Protection" className="mx-auto h-20 w-20 mb-4"/>
          <h3 className="text-lg font-semibold">Protection</h3>
        </div>

        {/* Icon 7 */}
        <div className="text-center border border-gray-300 rounded-lg p-4">
          <img src={icon7} alt="Strong leadership" className="mx-auto h-20 w-20 mb-4"/>
          <h3 className="text-lg font-semibold">Strong leadership</h3>
        </div>

        {/* Icon 8 */}
        <div className="text-center border border-gray-300 rounded-lg p-4">
          <img src={icon8} alt="Value added partner" className="mx-auto h-20 w-20 mb-4"/>
          <h3 className="text-lg font-semibold">Value added partner</h3>
        </div>
      </div>
    </div>
  );
}
