import React from 'react';
import icon1 from '../../images/icons/icon1.png'; 
import icon2 from '../../images/icons/icon2.png'; 
import icon3 from '../../images/icons/icon3.png'; 
import backgroundPCB from '../../images/pcb-bg1.png';

export default function RelationsAdvice() {
  return (
    <div
      className="bg-opacity-50 bg-gray-400"
      style={{ 
        backgroundImage: `url(${backgroundPCB})`, // Corrected syntax for background image
        backgroundSize: 'contain' 
      }}
    >
      <div className="bg-gray-200 bg-opacity-80 p-8 rounded-md mx-auto max-w-full shadow-lg text-center">
        <h1 className="text-4xl font-bold mb-10 text-[#150A76]">
          Experienced in Business Development and Strategic Advisory
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="text-center">
            <img src={icon1} alt="Expert Team Members" className="w-full h-20 object-contain rounded-md mb-4" style={{ objectFit: 'contain' }} />
            <h3 className="text-lg font-semibold text-gray-700">Expert Team Members</h3>
          </div>
          <div className="text-center">
            <img src={icon2} alt="Solution For Business" className="w-full h-20 object-contain rounded-md mb-4" style={{ objectFit: 'contain' }} />
            <h3 className="text-lg font-semibold text-gray-700">Solution For Business</h3>
          </div>
          <div className="text-center">
            <img src={icon3} alt="World Class EMS Provider" className="w-full h-20 object-contain rounded-md mb-4" style={{ objectFit: 'contain' }} />
            <h3 className="text-lg font-semibold text-gray-700">World Class EMS Provider</h3>
          </div>
        </div>
      </div>
    </div>
  );
}
