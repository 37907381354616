import React from 'react'
import PCBAssemblyJobHead from '../components/Services/PCB Assembly Job/PCBAssemblyHead'
import PCBAssemblyJob from '../components/Services/PCB Assembly Job/PCBAssemblyJob'

export default function PCBAssemblyPage() {
  return (
    <div>
      <PCBAssemblyJobHead/>
      <PCBAssemblyJob/>
    </div>
  )
}
