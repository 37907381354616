import React, { useState } from 'react';

export default function EMSForm() {
  const [formData, setFormData] = useState({
    name: '',
    number: '',
    email: '',
    subject: '',
    message: '',
    services: {
      pcbm: false,
      pcba: false,
      component: false,
    },
  });

  const [errors, setErrors] = useState({});

  // Handle input change
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData({
        ...formData,
        services: {
          ...formData.services,
          [name]: checked,
        },
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    if (name === 'number') {
      validateNumber(value);
    }
  };

  // Validate number field (only 10 digits allowed)
  const validateNumber = (number) => {
    const isValid = /^[0-9]{10}$/.test(number);
    if (!isValid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        number: 'Enter a valid 10-digit number',
      }));
    } else {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors.number;
        return newErrors;
      });
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform final validation on submission
    if (!formData.number || formData.number.length !== 10) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        number: 'Enter a valid 10-digit number',
      }));
      return;
    }

    console.log(formData);
    // Add your form submission logic here (e.g., API call)
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-8">
      <form onSubmit={handleSubmit} className="w-full max-w-lg bg-white p-8 rounded-lg shadow-md">
        
        {/* Name Input */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
            Name
          </label>
          <input
            id="name"
            name="name"
            type="text"
            placeholder="Enter your name"
            value={formData.name}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        
        {/* Number Input */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="number">
            Number
          </label>
          <input
            id="number"
            name="number"
            type="tel"
            placeholder="Enter your 10-digit number"
            value={formData.number}
            onChange={handleChange}
            className={`w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 ${
              errors.number ? 'border-red-500 focus:ring-red-500' : 'focus:ring-blue-500'
            }`}
            required
          />
          {errors.number && (
            <p className="text-red-500 text-sm mt-1">{errors.number}</p>
          )}
        </div>

        {/* Email Input */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Email
          </label>
          <input
            id="email"
            name="email"
            type="email"
            placeholder="Enter your email"
            value={formData.email}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        {/* Subject Input */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="subject">
            Subject
          </label>
          <input
            id="subject"
            name="subject"
            type="text"
            placeholder="Subject"
            value={formData.subject}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        {/* Message Textarea */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
            Message
          </label>
          <textarea
            id="message"
            name="message"
            placeholder="Write your message"
            value={formData.message}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows="5"
            required
          ></textarea>
        </div>

        {/* Services Checkboxes */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Services</label>
          <div className="flex items-center mb-2">
            <input
              id="pcbm"
              name="pcbm"
              type="checkbox"
              checked={formData.services.pcbm}
              onChange={handleChange}
              className="mr-2"
            />
            <label htmlFor="pcbm" className="text-gray-700">PCBM</label>
          </div>
          <div className="flex items-center mb-2">
            <input
              id="pcba"
              name="pcba"
              type="checkbox"
              checked={formData.services.pcba}
              onChange={handleChange}
              className="mr-2"
            />
            <label htmlFor="pcba" className="text-gray-700">PCBA</label>
          </div>
          <div className="flex items-center mb-2">
            <input
              id="component"
              name="component"
              type="checkbox"
              checked={formData.services.component}
              onChange={handleChange}
              className="mr-2"
            />
            <label htmlFor="component" className="text-gray-700">Component</label>
          </div>
        </div>

        {/* Submit Button */}
        <div className="text-center">
          <button
            type="submit"
            className="bg-blue-500 text-white px-6 py-2 rounded-lg shadow hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
