import React from 'react';
import PCBAssemblyVideo from '../../../videos/printed circuit board assembly (pcba).webm';

export default function PCBAssemblyJob() {
  return (
    <div className="flex flex-col md:flex-row items-start gap-6 p-4 sm:p-6 md:p-10 lg:p-14 h-auto md:h-[500px] bg-white">
      <div className="space-y-4 w-full md:w-1/2 h-auto">
        <p className="leading-relaxed text-sm sm:text-base">
          We understand the market need and provide superior quality range of PCB Assembly JOB Work (Circuit Board Assembly) Soldering using best technologies. We maintain the international standard of quality and supply our products in accurate sizes. These are well tested on various quality parameters and are supplied all across the nation at the most economical market price range.
        </p>
        <h3 className="text-md sm:text-lg font-semibold text-gray-800">Features:</h3>
        <ul className="list-disc list-inside pl-4 text-sm sm:text-base">
          <li>Well tested</li>
          <li>Quality assured</li>
          <li>Secure and advanced</li>
        </ul>
        <p className="leading-relaxed text-sm sm:text-base">
          PCB Preparation: The PCB is inspected for any defects, cleaned, and prepared for component placement. This may involve applying solder paste or adhesive, depending on the assembly method.
          Component Placement: Automated pick-and-place machines or manual assembly is used to accurately place the components onto the PCB. The machine or operator picks up the components and positions them on their designated pads.
        </p>
      </div>
      <div className="w-full md:w-1/2 h-48 sm:h-56 md:h-full">
        <iframe 
          src={PCBAssemblyVideo} 
          className="w-full h-full rounded-lg shadow-lg object-cover" 
          frameBorder="0" 
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}
