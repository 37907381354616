import React from 'react'
import PCBAutomatic from '../components/Services/PCB Automatic Assembly/PCBAutomaticAssembly';
import PCBAutomaticHead from '../components/Services/PCB Automatic Assembly/PCBAutomaticAssemblyHead'


export default function PCBAutomatctAssemblePage() {
  return (
    <div>
      <PCBAutomaticHead/>
      <PCBAutomatic/>
    </div>
  )
}
