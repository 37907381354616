import React from 'react'
import SMDPCBhead from '../components/Services/SMD-WPCB/SMDPCBHead'
import SMDPCB from '../components/Services/SMD-WPCB/SMDWPCB'
export default function SMD_WPCBpage() {
  return (
    <div>
      <SMDPCBhead/>
      <SMDPCB/>
    </div>
  )
}
