import React from 'react';
import LEDlightVideo from '../../../videos/led-lighting.mp4';

export default function LEDLightCircuit() {
    return (
        <div className="bg-white p-4 sm:p-6 md:p-10 lg:p-14 text-gray-700">
            <div className="flex flex-col md:flex-row items-start gap-6 md:gap-8 h-auto">
                <div className="space-y-4 w-full md:w-1/2">
                    <p className="leading-relaxed text-gray-700 text-sm sm:text-base">
                        With the use of modern technology, we are able to provide a sustainable range of LED Light Circuit Board Assembly (Paste Soldering through reflow Machine). We are supported by extremely talented professionals who apply innovative skills to provide an advanced range of products. These are precisely developed and supply verified by quality experts. We safely supply our products on a wide scale at the beneficial market price range.
                    </p>
                    <h3 className="text-md sm:text-lg font-semibold text-gray-800">Features:</h3>
                    <ul className="list-disc list-inside pl-4 text-gray-700 text-sm sm:text-base">
                        <li>Resistors: Limit the flow of current in the circuit to control LED brightness and prevent excessive current.</li>
                        <li>Capacitors: Store and release electrical energy, often used to stabilize voltage and reduce electrical noise.</li>
                        <li>Circuit Board: Also known as a PCB, it mounts and interconnects components, with copper traces for signal flow.</li>
                    </ul>
                </div>
                <div className="w-full md:w-1/2 h-40 sm:h-52 md:h-64 lg:h-72">
                    <iframe
                        src={LEDlightVideo}
                        className="w-full h-full rounded-lg shadow-lg object-cover"
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
        </div>
    );
}
