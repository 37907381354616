import React from 'react'
import QMS from '../components/Team/QMS'
import Teams from '../components/Team/Teams'

export default function TeamPage() {
  return (
    <div>
      <QMS/>
      <Teams/>
    </div>
  )
}
