import React from 'react';
import SMDWPCBvideo from '../../../videos/SMD-WPCB.mp4';

export default function SMDWPCB() {
  return (
    <div className="bg-white p-4 sm:p-6 md:p-10 lg:p-14 text-gray-700">
      <div className="flex flex-col md:flex-row items-start gap-6 h-auto">
        <div className="space-y-4 w-full md:w-1/2">
          <p className="leading-relaxed text-gray-700 text-sm sm:text-base">
            Manufacturer & Supplier of SMD IC Work Printed Circuit Board Assembly. Our product range also comprises of Printed Circuit Board Assembly, LED Light Circuit Board Assembly.
            With the support of our proficient employees, we are able to provide an advanced range of SMD IC Work Printed Circuit Board Assembly. Our products are precisely shaped and devised under the guidance of experts and are used in several requirements. We are a prominent choice in the market, providing secure products with quality assurance at competitive prices.
          </p>
          <h3 className="text-md sm:text-lg font-semibold text-gray-800">Features:</h3>
          <ul className="list-disc list-inside pl-4 text-gray-700 text-sm sm:text-base">
            <li>Accurate size</li>
            <li>Superior quality</li>
            <li>Advanced variety</li>
          </ul>
          <p className="leading-relaxed text-gray-700 text-sm sm:text-base">
            PCB Design: The PCB design is created using computer-aided design (CAD) software, specifying component placement locations and connecting traces.
            Stencil Production: A stencil is created to apply solder paste onto the PCB, with openings corresponding to the SMD IC pads on the PCB.
          </p>
        </div>
        <div className="w-full md:w-1/2 h-40 sm:h-48 md:h-64 lg:h-72">
          <iframe 
            src={SMDWPCBvideo} 
            className="w-full h-full rounded-lg shadow-lg object-cover" 
            frameBorder="0" 
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}
