import React from 'react'
import EMS from '../components/Solution/EMS/EMS';
import EMSForm from '../components/Solution/EMS/EMSForm';
import PageHead from '../components/Solution/EMS/PageHead'

export default function EMSPages() {
  return (
    <div>
      <PageHead/>
      <EMS/>
      <EMSForm/>
    </div>
  )
}
