import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HeaderSocialIcon from './components/HeaderSocialIcon.jsx'
import Header from './components/Header.jsx';
import Footer from './components/Footer.jsx';
import Home from './Pages/Home.jsx';
import AboutUs from './Pages/AboutUs.jsx';
// Solutions
import EMSPages from './Pages/EMSPages.jsx';
import PCBpage from './Pages/Pcbpages.jsx';
import PrototypePCBpage from './Pages/PrototypePCBpage.jsx'
import StencilePCBpage from './Pages/StencilePCBpage.jsx';
// Services
import SMDpage from './Pages/SMDpage.jsx';
import SMDWPCBpage from './Pages/SMD_WPCBpage.jsx';
import PCBAssemblyPage from './Pages/PCBAssemblyPage.jsx';
import PCBAutomatctAssemblePage from './Pages/PCBAutomatctAssemblePage.jsx';
import LEDliightpage from './Pages/LEDlightPage.jsx'
// gallery
import GalleryPage from './Pages/EMSPages.jsx'
// team
import TeamPage from './Pages/TeamPage.jsx';
// Locate Us
import ContactPage from './Pages/ContactPage.jsx'


function App() {
  return (
    <BrowserRouter>
      <HeaderSocialIcon/>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<AboutUs />} />
        {/* Solution pages */}
        <Route path='/ems' element={<EMSPages/>} />
        <Route path='/pcb' element={<PCBpage />} />
        <Route path='/prototypePCB' element={<PrototypePCBpage />} />
        <Route path='/StencilePCB' element={<StencilePCBpage />} />

        {/* Service */}
        <Route path='/smd' element={<SMDpage />} />
        <Route path='/smdwpc' element={<SMDWPCBpage />} />
        <Route path='/pcbaAssebJob' element={<PCBAssemblyPage />} />
        <Route path='/PCBAutomatctAssemblePage' element={<PCBAutomatctAssemblePage />} />
        <Route path='/ledLight' element={<LEDliightpage />} />

        {/* GalleryPage */}
        <Route path='/gallery' element={<GalleryPage />} />

        {/*TeamPage  */}
        <Route path='/team' element={<TeamPage />} />

        {/*contact  */}
        <Route path='/contact' element={<ContactPage />} />
        
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
