import React from 'react'
import PCBAHead from '../components/Solution/PCBA/PCBAHead'
import PCBA from '../components/Solution/PCBA/PCBA'
import PCBAForm from '../components/Solution/PCBA/PCBAForm'

const Pcbpages = () => {
  return (
    <div>
        <PCBAHead/>
        <PCBA/>
        <PCBAForm/>

    </div>
  )
}

export default Pcbpages