import React from 'react';
import Automibile from '../../images/Automobile.jpg';
import Electronics from '../../images/Consumer Electronics.jpg';
import Instrumentation from '../../images/Instrumentation.jpg';
import backgroundImage from '../../images/PCB.jpg';

export default function MarketServed() {
  return (
    <div
      className="relative bg-cover bg-center h-[65vh] flex items-center justify-center"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="bg-white bg-opacity-80 p-8 rounded-md text-center shadow-lg">
        <h1 className="text-4xl font-bold mb-4 text-[#150A76]">Markets Served</h1>
        <h3 className="text-xl font-medium text-gray-800 mb-8">
          Swastik Electrotech Automation brings extensive expertise in providing integrated design and
          manufacturing solutions tailored to a diverse range of industries, including:
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="text-center">
            <img src={Automibile} alt="Automobile" className="w-full h-44 object-cover rounded-md mb-4" />
            <h3 className="text-lg font-semibold text-gray-800">Automobile</h3>
          </div>
          <div className="text-center">
            <img src={Instrumentation} alt="Instrumentation" className="w-full h-44 object-cover rounded-md mb-4" />
            <h3 className="text-lg font-semibold text-gray-800">Instrumentation</h3>
          </div>
          <div className="text-center">
            <img src={Electronics} alt="Consumer Electronics" className="w-full h-44 object-cover rounded-md mb-4" />
            <h3 className="text-lg font-semibold text-gray-800">Consumer Electronics</h3>
          </div>
        </div>
      </div>
    </div>
  );
}
