import React from 'react';
import SMDvideo from '../../../videos/SMD.mp4';

export default function SMD() {
  return (
    <div className="bg-white p-4 sm:p-6 md:p-10 lg:p-14 text-gray-700">
      <div className="flex flex-col md:flex-row items-start gap-6 h-auto">
        <div className="space-y-4 w-full md:w-1/2">
          <p className="leading-relaxed text-gray-700 text-sm sm:text-base">
            With the use of advanced technology, we develop the most reliable and sustainable SMD through-hole soldering. We work under the guidance of quality analysts and use the best raw components to design optimum products. These are well-tested, safely packed, and are largely supplied to various clients. We provide them in various sizes at attractive market price ranges.
          </p>
          <p className="leading-relaxed text-gray-700 text-sm sm:text-base">
            SMD components are typically smaller and more compact than their through-hole counterparts, allowing for greater miniaturization and higher circuit densities. They are widely used in various electronic devices and equipment, such as smartphones, laptops, televisions, and automotive electronics. SMD technology has revolutionized the electronics industry by enabling the production of smaller, lighter, and more efficient electronic devices.
          </p>
          <h3 className="text-md sm:text-lg font-semibold text-gray-800">Features:</h3>
          <ul className="list-disc list-inside pl-4 text-gray-700 text-sm sm:text-base">
            <li>Accurate size</li>
            <li>Superior quality</li>
            <li>Advanced variety</li>
          </ul>
        </div>
        <div className="w-full md:w-1/2 h-40 sm:h-48 md:h-64 lg:h-80">
          <iframe 
            src={SMDvideo} 
            className="w-full h-full rounded-lg shadow-lg object-cover" 
            frameBorder="0" 
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}
