import React from 'react'
import ContactUs from '../components/Contact/ContactUs';
import ContectForm from '../components/Contact/ContactForm'


export default function ContactPage() {
  return (
    <div>
      <ContactUs/>
      <ContectForm/>
    </div>
  )
}
